import request from '@/utils/request'

/**
 * 
 * get-params
 * post-data
 */

//获取资讯列表
export function getNews(params) {
    return request ({
        url: '/journ/ams',
        method: 'get',
        params
    })
}

//获取资讯详情
export function getNewsDetail(params) {
    return request ({
        url: '/journ/list',
        method: 'get',
        params
    })
}
