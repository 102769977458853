<template>
  <div id="app">
    <!-- 页面顶部 -->
    <nav :class="isInfomation ? 'navConTwo' : 'navCon'" v-if="isSow">
      <img src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/logoheader.png" @click="goHome()" style="cursor: pointer;"/>
      <div class="conCenter">
        <router-link to="/pages/index/index">首页</router-link>
        <router-link to="/newHouse">新房</router-link>
        <router-link to="/office">商铺写字楼</router-link> 
        <router-link to="/pages/news/index">资讯</router-link>
        <router-link to="/allVideo">房产视频</router-link>
        <router-link to="/soft">媒体软文</router-link>
      </div>
      <div class="conRight" v-if="!isInfomation">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          @change="goSearch()"
          v-model="topKeyWord"
        >
        </el-input>
      </div>
    </nav>
    <!-- 路由页面 -->
    <router-view class="routerView" />
    <!-- 页面底部 -->
    <bottomFooter v-if="showFoot && isSow" />
  </div>
</template>
<script>
import bottomFooter from '@/components/subFooter.vue'
export default {
  components: { bottomFooter },
  data() {
    return {
      isInfomation: false,
      showFoot: true,
      topKeyWord: '',
      isSow: true
    }
  },
  created() {},
  watch: {
    $route(to, from) {
      this.$store.state.routeToName = to.name
      this.$store.state.routerFromName = from.name
      if (
        // to.name == 'InforMationindex' ||
        // to.name == 'InfoDetail' ||
        to.name == 'WikiDetail' ||
        to.name == 'TypeDetail' ||
        to.name == 'RentDetail' ||
        to.name == 'RentList' ||
        to.name == 'VillaIndex' ||
        to.name == 'VillaDetail' ||
        to.name == 'SaleIndex' ||
        to.name == 'AboutUs' ||
        to.name == 'ContactUs' ||
        to.name == 'DisClaimer' ||
        to.name == 'ProMotion'
      ) {
        this.isInfomation = true
      } else {
        this.isInfomation = false
      }
      if (to.name == 'SoftIndex') {
        this.showFoot = false
      } else {
        this.showFoot = true
      }
      if(to.name == 'PullPage') {
        this.isSow = false
      } else {
        this.isSow = true
      }
    },
  },
  methods: {
    goHome() {
      this.$router.push('./views/home/index.vue')
    },
    goSearch() {
      if (this.topKeyWord) {
        localStorage.setItem('topKeyWord', this.topKeyWord)
        if (this.$store.state.routeToName != 'NewHouse') {
          this.$router.push({
            path: '/newHouse',
          })
          setTimeout(this.topKeyWord = '', 4000)
        } else {
          this.$router.go(0)
          return
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .navCon {
    box-shadow: 0px 0px 10px #e0dede;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
    padding: 5px 0;
    img {
      width: 85px;
      height: 45px;
    }
    .conCenter {
      min-width: 600px;
      a {
        color: #333;
        text-decoration-line: none;
        font-size: 16px;
        margin: 0 20px;

        &.router-link-exact-active {
          color: #000;
          font-weight: 600;
        }
      }
    }
    .conRight {
      min-width: 200px;
      :deep() {
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #ccc !important;
        }
        .el-input__inner{
          height: 30px;
          line-height: 30px;
        }
        .el-input__icon{
          line-height: 30px;
        }
      }
    }
  }
  .navConTwo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #363a3d;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
    padding: 5px 0;
    img {
      width: 85px;
      height: 45px;
    }
    .conCenter {
      min-width: 800px;
      margin-right: 50px;
      a {
        color: #fff;
        text-decoration-line: none;
        font-size: 16px;
        margin: 0 40px;

        &.router-link-exact-active {
          color: #fff;
          font-weight: 600;
        }
      }
    }
    .conRight {
      :deep() {
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #ff1b01 !important;
        }
      }
    }
  }
}
</style>
