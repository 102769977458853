import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/pages/index/index',
    name: 'HomeIndex',
    component: Home,
    meta: {
      title: '新房_二手房_商铺_写字楼_头条_新闻_资讯_房地产_首页-百房-duodianfang-百房-baifun',
      content: {
        keywords: '深圳房产网,深圳新房,深圳二手房,深圳商铺,深圳写字楼，深圳楼市,百房,深圳周边',
        description: '百房专注于房地产行业,为用户提供找房信息。包括深圳及深圳周边新房、二手房、商铺、写字楼、楼市政策、楼盘新闻资讯等。为品牌地产商提供全网营销推广服务,包括SEM搜索营销,电商推广,社交化传播,网站建设,自媒体营销,活动营销等。',
      }
    }
  },
  // 新房
  {
    path: '/newHouse',
    name: 'NewHouse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/newHouse/index.vue'),
      meta: {
        title: '新房_华南地区新房_房地产-百房-duodianfang-百房-baifun',
        content: {
          keywords: '深圳新房,深圳周边新房,大湾区新房,一手房,深圳楼盘',
          description: '百房为您提供新开深圳楼盘信息，深圳新房房源信息以及深圳周边城市新开房源信息。查找深圳新开楼盘信息，查找深圳楼盘周边配套信息，就来百房，百房为您实现家的梦想。',
        }
      }
  },
  // 房产视频
  {
    path: '/allVideo',
    name: 'AllVideo',
    component: () => import('../views/home/allVideo.vue'),
  },
  // 写字楼
  {
    path: '/office',
    name: 'OfficeIndex',
    component: () => import('../views/office/index.vue'),
  },
  // 写字楼详情
  {
    path: '/pages/office/officeDetail',
    name: 'OfficeDetail',
    component: () => import('../views/office/officeDetail.vue'),
  },
  // 别墅
  {
    path: '/pages/villa/index',
    name: 'VillaIndex',
    component: () => import('../views/villa/index.vue'),
  },
  // 别墅详情
  {
    path: '/pages/villa/villaDetail',
    name: 'VillaDetail',
    component: () => import('../views/villa/villaDetail.vue'),
  },
  // 租房
  {
    path: '/pages/rent/index',
    name: 'RentHouse',
    component: () => import('../views/rentHouse/index.vue'),
  },
  // 资讯
  {
    path: '/pages/news/index',
    name: 'InforMationindex',
    component: () => import('../views/information/index.vue'),
    meta: {
      title: '新闻_资讯_头条_楼市新闻_楼市动态_楼市行情政策_深圳楼市新闻-百房-duodianfang-百房-baifun',
      content: {
        keywords: '深圳楼市新闻,深圳楼市政策,深圳楼市行情,深圳楼市资讯,楼盘新闻',
        description: '百房房产新闻为您提供深圳最新楼市新闻、深圳楼市动态、深圳房产交易政策等。了解楼市动态，解决楼市楼盘房产相关问题',
      }
    }
  },
  // 媒体软文
  {
    path: '/soft',
    name: 'SoftIndex',
    component: () => import('../views/soft/index.vue'),
  },
  // 新房详情
  {
    path: '/pages/index/houseDetail',
    name: 'HouseDetail',
    component: () => import('../views/home/houseDetail.vue'),
  },
  // 资讯详情
  {
    path: '/pages/news/newsDetail',
    name: 'InfoDetail',
    component: () => import('../views/information/infoDetail.vue'),
  },
  // 户型详情
  {
    path: '/pages/index/typeDetail',
    name: 'TypeDetail',
    component: () => import('../views/home/typeDetail.vue'),
  },
  // 互动百科详情
  {
    path: '/pages/index/wikiDetail',
    name: 'WikiDetail',
    component: () => import('../views/home/wikiDetail.vue'),
  },
  // 租房列表
  {
    path: '/rentList',
    name: 'RentList',
    component: () => import('../views/rentHouse/rentList.vue'),
  },
  // 租房详情
  {
    path: '/pages/rent/rentDetail',
    name: 'RentDetail',
    component: () => import('../views/rentHouse/rentDetail.vue'),
  },
  // 销售主页
  {
    path: '/pages/index/sale',
    name: 'SaleIndex',
    component: () => import('../views/sale/index.vue'),
  },
  // 推广页
  {
    path: '/pages/index/promotion',
    name: 'ProMotion',
    component: () => import('../views/home/proMotion.vue'),
  },
  // 拉页
  {
    path: '/ly/pullPage',
    name: 'PullPage',
    component: () => import('../views/ly/pullPage.vue'),
  },
  // 公司简介
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('../views/home/aboutUs.vue'),
    meta: {
      title: '关于我们-百房-duodianfang-百房-baifun',
      content: {
        keywords: '关于我们,关于百房,业务介绍',
        description: '百房网络科技有限公司专注于房地产行业，为品牌地产商提供全网营销推广服务，包括SEM搜索营销,电商推广,社交化传播,网站建设,自媒体营销,活动营销等。',
      }
    }
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/home/contactUs.vue'),
    meta: {
      title: '联系我们-百房-duodianfang-百房-baifun',
      content: {
        keywords: '联系我们,联系百房,业务咨询,交流合作',
        description: '百房网络科技有限公司联系方式及地址基本信息介绍，欢迎来电咨询合作，咨询业务，欢迎上门咨询'
      }
    }
  },
  // 免责声明
  {
    path: '/pages/index/relief',
    name: 'DisClaimer',
    component: () => import('../views/home/disClaimer.vue'),
    meta: {
      title: '免责声明-百房-duodianfang-百房-baifun',
      content: {
        keywords: '免责声明,百房免责条款',
        description: '百房网站所有内容仅供参考，实际以现场实际勘探或对应国家政策为准，百房公司不承担法律责任及相关连带责任'
      }
    }
  },
  {
    path: '/*',
    name: 'NoView',
    redirect: {name:'HomeIndex'}
  },
]

const router = new VueRouter({
  base: '/investment/',
  mode: 'history',
  // base: process.env.BASE_URL,hash/history
  routes,
})

export default router
