<template>
  <div>
    <h2 id="sectionTitle">
      <span
        :class="item.isSale ? 'isSale' : 'unSale'"
        v-for="item in saleType"
        :key="item.label"
        @click="handelSaleType(item)"
        >{{ item.name }}</span
      >
    </h2>
    <div class="saleCon">
      <div class="conTop">
        <span
          :class="item.isSelect ? 'isSelect' : 'unSelect'"
          v-for="item in areaList"
          :key="item.ctqybm"
          @click="handelArea(item)"
          >{{ item.ctqyname }}</span
        >
      </div>
      <el-empty description="暂无数据" v-if="houseList.length === 0"></el-empty>
      <div class="conFooter" v-else>
        <div
          v-for="(item, index) in houseList"
          :key="item.lpid"
          :class="isCoop && index < showRed ? 'footerItem2' : 'footerItem'"
          @click="goDetail(item)"
        >
          <span>{{ item.lpname }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArea, getHouse } from '@/api/home.js' // 首页相关接口
export default {
  name: 'SaleOrOpening',
  data() {
    return {
      areaList: [], // 深圳地区列表
      houseList: [], // 深圳新房列表
      ctqybm: '', // 临深楼盘（筛选 地区id)
      xsztId: 2, // 在售2 即将开盘1
      isCoop: true, // 是否合作楼盘
      showRed: '', // 显示红色的合作楼盘个数
      saleType: [
        {
          name: '在售新房',
          label: 2,
          isSale: true,
        },
        {
          name: '即将开盘',
          label: 1,
          isSale: false,
        },
      ],
    }
  },
  created() {
    // 深圳地区
    this.getAreaData({
      ctid: this.$store.state.ctid,
      pageNum: 1,
      pageSize: 100,
    }),
      this.getHouseRecord()
  },
  methods: {
    // 查看详情
    goDetail(info) {
      this.$router.push({
        name: 'HouseDetail',
        query: { lpid: info.lpid },
      })
    },
    // 新房列表接口
    getHouseRecord() {
      // 新房列表
      this.getHouseData({
        newbqId: 10,
        ctid: this.$store.state.ctid,
        pageSize: this.isCoop ? '18' : '28',
        pageNum: 1,
        ctqybm: this.ctqybm,
        xsztId: this.xsztId,
      })
    },
    // 获取新房列表
    async getHouseData(params) {
      await getHouse(params)
        .then((res) => {
          this.houseList = res.data.records
          this.areaList.map((item) => {
            if (item.ctqyname == '不限' && item.isSelect) {
              this.getHouseDataCoop()
            }
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取新房列表——合作楼盘
    async getHouseDataCoop() {
      await getHouse({
        newbqId: 10,
        ctid: this.$store.state.ctid,
        pageNum: 1,
        pageSize: 10,
        xsztId: this.xsztId,
        iscooperate: '是',
      })
        .then((res) => {
          this.showRed = res.data.records.length
          this.houseList.unshift(...res.data.records)
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取深圳地区
    async getAreaData(params) {
      await getArea(params)
        .then((res) => {
          this.areaList = res.data.records
          this.areaList.map((item) => {
            item.isSelect = false
          })
          this.areaList.unshift({
            ctqyname: '不限',
            isSelect: true,
            ctqybm: null,
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 选中深圳地区筛选
    handelArea(info) {
      if (info.ctqyname == '不限') {
        this.xsztId = 2
        this.isCoop = true
      } else {
        this.xsztId = info.label
        this.isCoop = false
      }
      this.areaList.map((item) => {
        if (info.ctqybm === item.ctqybm) {
          this.ctqybm = info.ctqybm
          item.isSelect = true
        } else {
          item.isSelect = false
        }
      })
      this.getHouseRecord()
      this.$forceUpdate()
    },
    //切换是否在售
    handelSaleType(info) {
      this.xsztId = info.label
      this.saleType.map((item) => {
        if (info.label === item.label) {
          item.isSale = true
        } else {
          item.isSale = false
        }
      })
      this.getHouseRecord()
    },
  },
}
</script>

<style lang="scss" scoped>
#sectionTitle {
  margin: 35px 0 20px 0;
  text-align: left;
  .isSale {
    background-color: #c1c1c1;
    padding: 5px 10px;
    border-radius: 6px;
    margin-right: 20px;
    cursor: pointer;
  }
  .unSale {
    margin-right: 20px;
    cursor: pointer;
  }
}
.saleCon {
  padding: 30px 100px;
  box-shadow: 0px 0px 10px #e0dede;
  border-radius: 20px;
  height: 210px;
  :deep() {
    .el-empty{
      padding: 0;
    }
  }
  .conTop {
    text-align: left;
    span {
      margin: 0 25px 0 0;
      cursor: pointer;
    }
    .isSelect {
      text-align: left;
      font-size: 20px;
      color: #000;
      font-weight: 600;
    }
    .unSelect {
      text-align: left;
      font-size: 18px;
      color: #333;
    }
  }
  .conFooter {
    margin-top: 30px;
    text-align: left;
    .footerItem {
      font-size: 14px;
      color: #767676;
      text-align: left;
      display: inline-block;
      margin: 10px 0;
      width: calc((100% - 50px) / 7);
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .footerItem2 {
      font-size: 14px;
      color: #df2f2f;
      text-align: left;
      display: inline-block;
      margin: 10px 0;
      width: calc((100% - 50px) / 7);
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
