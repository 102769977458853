import request from '@/utils/request'

/**
 * 
 * get-params
 * post-data
 */

//获取互动百科列表
export function getEngage(params) {
    return request ({
        url: '/encyclopedia/encyclopediaList',
        method: 'get',
        params
    })
}

