<template>
  <swiper ref="mySwiper" :options="swiperOptions" watchSlidesProgress>
    <swiper-slide v-for="item in list" :key="item.lpid">
      <div class="suggestContent" @click="goDetail(item)">
        <div class="suggestImg">
          <img :src="item.cxfmtpurl" alt="" />
        </div>
        <div class="suggestTitle">{{ item.lpname }}</div>
        <div class="suggestPrice">
          均价：<span style="color: #ff1b01" v-if="item.junj"
            >{{ item.junj }}元/m²</span
          >
          <span style="color: #ff1b01" v-else>待定</span>
        </div>
        <div class="suggestTips">[{{ item.ctname }}] {{ item.lpwzms }}</div>
      </div>
    </swiper-slide>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
// swiper轮播
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'SuggestSwiper',
  components: {
    Swiper,
    SwiperSlide,
  },
  //   接收数据
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      // swiper配置
      swiperOptions: {
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slidesPerView: 4.3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  methods: {
    // 查看详情
    goDetail(info) {
      this.$router.push({
        name: 'HouseDetail',
        query: { lpid: info.lpid },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.swiper-container {
  width: 100%;
  padding: 20px 0 20px;
  .swiper-slide {
    text-align: center;
    background-color: #fff;
    padding: 30px 20px;
    box-shadow: 0px 0px 20px #e0dede;
    border-radius: 20px;
    margin-right: 10px;
    width: 240px !important;
    .suggestContent {
      cursor: pointer;
      .suggestImg {
        text-align: center;
        // width: 261px;
        height: 138px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      > div {
        text-align: left;
      }
      .suggestTitle {
        color: #333;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px;
      }
      .suggestPrice {
        color: #333;
        font-size: 16px;
        margin: 10px 0;
      }
      .suggestTips {
        color: #333;
        font-size: 16px;
        height: 40px;
      }
    }
  }
  // 切换按钮样式
  // hover时才显示切换按钮
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    background-color: #666;
    opacity: 0.6;
    &:after {
      font-size: 18px;
    }
    // 第一个或最后一个隐藏切换按钮
    &.swiper-button-disabled {
      display: none;
    }
  }
}
</style>
