import Vue from "vue";
import Vuex from "vuex";
import { getBanner, getArea, getPrice, getHouse, getHouseInfo, getSzHouse, getMedium, getOffice } from "@/api/home.js"; // 首页相关接口
import { getNews } from "@/api/information.js"; // 资讯相关接口
import { getEngage } from "@/api/engage.js"; // 互动百科相关接口
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    routeToName: '', // 路由当前页
    routerFromName: '', // 路由上一页
    ctid: 2016, // 深圳id
    pageTotal: '', // 新房推荐（一页九个 只显示三页）
    bannerList: [], // banner图列表
    areaList: [], // 深圳地区列表
    priceList: [], // 价格区间列表
    houseList: {}, // 新房列表
    houseDetail: {}, // 新房详情
    newsList: [], // 资讯列表
    suggestHouseList: [], // 临深圳新楼盘推荐
    engageList: [], // 互动百科列表
    meaiumList: [], // 媒体软文列表
    officeList: [], // 写字楼列表
  },
  mutations: {
    /**
     * @param {*} state 仓库state
     * @param {*} params 请求参数
     */
    // 获取首页banner图
    async getBannerImg(state, params) {
      await getBanner(params)
        .then((res) => {
          state.bannerList = res.data.records;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取深圳地区
    async getAreaData(state, params) {
      await getArea(params)
        .then((res) => {
          state.areaList = res.data.records;
          state.areaList.map(item => {
            item.isSelect = false
          })
          state.areaList.unshift({
            ctqyname: '不限',
            isSelect: true,
            ctqybm: null,
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取价格区间
    async getPriceData(state, params) {
      await getPrice(params)
        .then((res) => {
          state.priceList = res.data.records;
          state.priceList.map(item => {
            item.isSelect = false
          })
          state.priceList.unshift({
            dj: '不限',
            djid: null,
            isSelect: true,
            djmaxvalue: null,
            djminvalue: null,
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取新房列表
    async getHouseData(state, params) {
      await getHouse(params)
        .then((res) => {
          state.houseList = res.data;
          // if(res.data.total >= 27) {
          //   state.pageTotal = 27
          // } else {
          //   state.pageTotal = res.data.total
          // }
          state.houseList.records.map(item => {
            if(item.lplabel === null) {
              item.lplabel = []
            } else {
              item.lplabel = item.lplabel.split(',')
              item.lplabel = item.lplabel.slice(0, 2)
            }
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取新房详情
    async getHouseDetailData(state, params) {
      await getHouseInfo(params)
        .then((res) => {
          state.houseDetail = res.data[0]
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取资讯列表
    async getNewsData(state, params) {
      await getNews(params)
        .then((res) => {
          state.newsList = res.data.records;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取临深楼盘列表（目前只查询惠州，不做切换
    async getSzHouseData(state, params) {
      await getSzHouse(params)
        .then((res) => {
          state.suggestHouseList = res.data.records;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取互动百科列表
    async getEngageData(state, params) {
      await getEngage(params)
        .then((res) => {
          state.engageList = res.data;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取媒体软文列表
    async getMediumData(state, params) {
      await getMedium(params)
        .then((res) => {
          state.meaiumList = res.data.records;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    // 获取写字楼列表
    async getOfficeData(state, params) {
      await getOffice(params)
        .then((res) => {
          state.officeList = res.data.records;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
  },
  actions: {},
  modules: {},
});
export default store;
