import { render, staticRenderFns } from "./officeSwiper.vue?vue&type=template&id=27d54f0b&scoped=true&"
import script from "./officeSwiper.vue?vue&type=script&lang=js&"
export * from "./officeSwiper.vue?vue&type=script&lang=js&"
import style0 from "./officeSwiper.vue?vue&type=style&index=0&id=27d54f0b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d54f0b",
  null
  
)

export default component.exports