import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/assets/css/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/iconfont/iconfont.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import vueWaterfallEasy from 'vue-waterfall-easy'

import BaiduMap from 'vue-baidu-map'
import VueCoreVideoPlayer from 'vue-core-video-player'

import axios from 'axios';

Vue.prototype.$http = axios;

axios.defaults.baseURL = 'https://www.duodianfang.com';

Vue.use(VueCoreVideoPlayer)
Vue.use(BaiduMap, {
  ak: 'CTQV0Nla1aGQ5p8Q1KF3DD9BWTuR3caP',
})

Vue.use(vueWaterfallEasy)
Vue.use(preview)
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App),
}).$mount('#app')
